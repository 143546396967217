import InstagramLogo from "./socials/InstagramLogo";
import FacebookLogo from "./socials/FacebookLogo";
import XLogo from "./socials/Xlogo";
import TelegramLogo from "./socials/TelegramLogo";
import SubstackLogo from "./socials/SubstackLogo";
import styles from "./ui.module.css";

export default function Socials() {
  return (
      <div className={styles.socials}>
        <a
          href="https://instagram.com/gath3rco"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.socialLink}
        >
          <InstagramLogo/>
        </a>
        <a
          href="https://facebook.com/gath3rco"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.socialLink}
        >
          <FacebookLogo/>
        </a>
        <a
          href="https://twitter.com/gath3rco"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.socialLink}
        >
          <XLogo/>
        </a>
      </div>
  );
}
