import { create } from "zustand";

interface DeviceState {
  isMobile: boolean;
  setMobile: (bool: boolean) => void;
}

interface ListingState {
  listings: any[];
  setListings: (listings: any[]) => void;
  isFetched: boolean;
  setIsFetched: (bool: boolean) => void;
}


interface ScrollStates {
  isScrolling: boolean;
  setIsScrolling: (bool: boolean) => void;
  toTop: number;
  setToTop: (number: number) => void;
  isScrollingDown: boolean;
  setIsScrollingDown: (bool: boolean) => void;
}

interface PreviewState {
  count: number;
  documents: any[];
  setDocuments: (documents: any[]) => void;
  isFetched: boolean;
  setIsFetched: (bool: boolean) => void;
}

interface ListingDialogState {
  listingId: string;
  setListingId: (id: string) => void;
}

interface CounterState {
  count: number;
  setCount: (count: number) => void;
}

interface UserState {
  user: any;
  setUser: (user: any) => void;
}

export const useDeviceState = create<DeviceState>((set) => ({
  isMobile: true,
  setMobile: (newState) => set((state) => ({ isMobile: newState })),
}));

export const useMediumState = create<ListingState>((set) => ({
  listings: [],
  setListings: (newState) => set((state) => ({ listings: newState })),
  isFetched: false,
  setIsFetched: (newState) => set((state) => ({ isFetched: newState })),
}));

export const useRssState = create<ListingState>((set) => ({
  listings: [],
  setListings: (newState) => set((state) => ({ listings: newState })),
  isFetched: false,
  setIsFetched: (newState) => set((state) => ({ isFetched: newState })),
}));

export const useRss3State = create<ListingState>((set) => ({
  listings: [],
  setListings: (newState) => set((state) => ({ listings: newState })),
  isFetched: false,
  setIsFetched: (newState) => set((state) => ({ isFetched: newState })),
}));

export const useListingDialogState = create<ListingDialogState>((set) => ({
  listingId: "",
  setListingId: (newState) => set((state) => ({ listingId: newState })),
}));

export const useProjectCounterState = create<CounterState>((set) => ({
  count: 0,
  setCount: (newState) => set((state) => ({ count: newState })),
}));

export const userReviewsCounterState = create<CounterState>((set) => ({
  count: 0,
  setCount: (newState) => set((state) => ({ count: newState })),
}));

export const useRatingsCounterState = create<CounterState>((set) => ({
  count: 0,
  setCount: (newState) => set((state) => ({ count: newState })),
}));

export const useProjectsPreviewState = create<PreviewState>((set) => ({
  count: 3,
  documents: [],
  setDocuments: (newState) => set((state) => ({ documents: newState })),
  isFetched: false,
  setIsFetched: (newState) => set((state) => ({ isFetched: newState })),
}));

export const useReviewsPreviewState = create<PreviewState>((set) => ({
  count: 1,
  documents: [],
  setDocuments: (newState) => set((state) => ({ documents: newState })),
  isFetched: false,
  setIsFetched: (newState) => set((state) => ({ isFetched: newState })),
}));

export const useRatingsPreviewState = create<PreviewState>((set) => ({
  count: 3,
  documents: [],
  setDocuments: (newState) => set((state) => ({ documents: newState })),
  isFetched: false,
  setIsFetched: (newState) => set((state) => ({ isFetched: newState })),
}));

export const useAchievementsPreviewState = create<PreviewState>((set) => ({
  count: 6,
  documents: [],
  setDocuments: (newState) => set((state) => ({ documents: newState })),
  isFetched: false,
  setIsFetched: (newState) => set((state) => ({ isFetched: newState })),
}));

export const useUserState = create<UserState>((set) => ({
  user: null,
  setUser: (newState) => set((state) => ({ user: newState })),
}));

export const useScrollStates = create<ScrollStates>((set) => ({
  isScrolling: false,
  setIsScrolling: (bool) => set((state) => ({ isScrolling: bool })),
  toTop: 0,
  setToTop: (number) => set((state) => ({ toTop: number })),
  isScrollingDown: false,
  setIsScrollingDown: (bool) => set((state) => ({ isScrollingDown: bool })),
}));
