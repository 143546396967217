import React from "react";
import styles from "../ui.module.css";

function FacebookLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 20"
      viewBox="0 0 500 499.93"
      className={styles.socialsLogoSvg}
    >
      <g fill="#3a3a3d">
        <path
          d="M987.67 672.65c42.7 131.27-29.09 272.29-160.36 315s-272.29-29.1-315-160.36 29.09-272.29 160.36-315a249.92 249.92 0 01315 160.36zm-237.6-150.12c-125.61.17-227.3 102.14-227.13 227.75s102.14 227.3 227.75 227.12S978 875.27 977.82 749.65a227.43 227.43 0 00-227.75-227.12z"
          transform="translate(-500 -500.03)"
        ></path>
        <path
          fillRule="evenodd"
          d="M790.89 663.82h27.83v-42.49c-4.33 0-8.83-1-13.5-1.33h-25a61.42 61.42 0 00-35.32 11 53 53 0 00-21.16 35.32 122.64 122.64 0 00-1.67 17v33.32h-40.48V764h40.16v118.8h50V763.46h40.16l4.83-46.82h-46.67v-33.32c1.33-15 9.66-19.16 20.82-19.5z"
          transform="translate(-500 -500.03)"
        ></path>
      </g>
    </svg>
  );
}

export default FacebookLogo;
