import Skeleton from "@mui/material/Skeleton";
import Avatar from "@mui/material/Avatar";
import { LazyLoadImg } from "../../../../components/ui/LazyLoadImg";
import { PfpViewer } from "../../../../components/ui/PfpViewer";
import {
  useAchievementsPreviewState,
  useUserState,
} from "../../../../components/utils/globalStates";

import "./PointsComp.css";

export default function AchievementsPreview() {
  const achievementsPreview = useAchievementsPreviewState((state) => state);
  const user = useUserState((state) => state);
  return (
    <div className="AchievementsPreviewMobile">
      <div className="AchievementsPreviewInnerMobile">
        {achievementsPreview.isFetched ? (
          achievementsPreview.documents.map((doc, index) => (
            <div className="AchievementsPreviewItemMobile" key={index}>
              <div className="AchievementsPreviewItemImgMobile">
                <LazyLoadImg
                  // @ts-ignore
                  imgUrl={doc.url}
                />
              </div>
              <div className="AchievementsPreviewItemInnerMobile">
                <p className="AchievementsPreviewItemTitleMobile">{doc.name}</p>
                <p className="AchievementsPreviewItemTextMobile">{doc.subtitle}</p>
              </div>
            </div>
          ))
        ) : (
          <>
            <div className="AchievementsPreviewItemMobile">
              <Skeleton
                variant="circular"
                sx={{
                  width: "28px",
                  height: "28px",
                  backgroundColor: "#F5E3FF",
                  marginTop: "5px",
                  marginBottom: "5px",
                }}
              />
              <div
                className="AchievementsPreviewItemInnerMobile"
                style={{ flexDirection: "row" }}
              >
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "18px", width: "100%" }}
                />
              </div>
            </div>
            <div className="AchievementsPreviewItemMobile">
              <Skeleton
                variant="circular"
                sx={{
                  width: "28px",
                  height: "28px",
                  backgroundColor: "#F5E3FF",
                  marginTop: "5px",
                  marginBottom: "5px",
                }}
              />
              <div
                className="AchievementsPreviewItemInnerMobile"
                style={{ flexDirection: "row" }}
              >
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "18px", width: "100%" }}
                />
              </div>
            </div>
            <div className="AchievementsPreviewItemMobile">
              <Skeleton
                variant="circular"
                sx={{
                  width: "28px",
                  height: "28px",
                  backgroundColor: "#F5E3FF",
                  marginTop: "5px",
                  marginBottom: "5px",
                }}
              />
              <div
                className="AchievementsPreviewItemInnerMobile"
                style={{ flexDirection: "row" }}
              >
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "18px", width: "100%" }}
                />
              </div>
            </div>
            <div className="AchievementsPreviewItemMobile">
              <Skeleton
                variant="circular"
                sx={{
                  width: "28px",
                  height: "28px",
                  backgroundColor: "#F5E3FF",
                  marginTop: "5px",
                  marginBottom: "5px",
                }}
              />
              <div
                className="AchievementsPreviewItemInnerMobile"
                style={{ flexDirection: "row" }}
              >
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "18px", width: "100%" }}
                />
              </div>
            </div>
            <div className="AchievementsPreviewItemMobile">
              <Skeleton
                variant="circular"
                sx={{
                  width: "28px",
                  height: "28px",
                  backgroundColor: "#F5E3FF",
                  marginTop: "5px",
                  marginBottom: "5px",
                }}
              />
              <div
                className="AchievementsPreviewItemInnerMobile"
                style={{ flexDirection: "row" }}
              >
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "18px", width: "100%" }}
                />
              </div>
            </div>
            <div className="AchievementsPreviewItemMobile">
              <Skeleton
                variant="circular"
                sx={{
                  width: "28px",
                  height: "28px",
                  backgroundColor: "#F5E3FF",
                  marginTop: "5px",
                  marginBottom: "5px",
                }}
              />
              <div
                className="AchievementsPreviewItemInnerMobile"
                style={{ flexDirection: "row" }}
              >
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "18px", width: "100%" }}
                />
              </div>
            </div>
          </>
        )}
      </div>
      {user.user ? (
        <div className="AchievementsLeaderItemMobile">
          <p className="AchievementsLeaderItemTitleMobile">GATH3R POINTS LEADER</p>
          <div className="AchievementsLeaderItemImgMobile">
            <PfpViewer
              // @ts-ignore
              pfpString={
                user.user.pfpString !== null &&
                user.user.pfpString !== undefined &&
                user.user.pfpString !== ""
                  ? user.user.pfpString
                  : ""
              }
              randomize={
                user.user.pfpString !== null &&
                user.user.pfpString !== undefined &&
                user.user.pfpString !== ""
                  ? false
                  : true
              }
            />
          </div>
          <p className="AchievementsLeaderItemNameMobile">{user.user.displayName}</p>
          <p className="AchievementsLeaderItemPointsMobile">{new Intl.NumberFormat().format(user.user.points)}</p>
        </div>
      ) : (
        <div className="AchievementsLeaderItemMobile">
          <p className="AchievementsLeaderItemTitleMobile">GATH3R POINTS LEADER</p>
          <div className="AchievementsLeaderItemImgMobile">
            <Skeleton
              variant="circular"
              sx={{
                width: "50px",
                height: "50px",
                  backgroundColor: "#F5E3FF",
                marginTop: "5px",
                marginBottom: "5px",
              }}
            />
            </div>
            <Skeleton
              variant="text"
              sx={{ fontSize: "18px", width: "80%" }}
            />
            <Skeleton
              variant="text"
              sx={{ fontSize: "18px", width: "80%", marginTop: "5px" }}
            />
        </div>
      )}
    </div>
  );
}
