import React, { useState } from "react";
import { Routes, Route } from "react-router-dom";
import Listeners from "./components/utils/Listeners";
import FirebaseListeners from "./components/utils/FirebaseListeners";
import StartView from "./views/StartView";
import Terms from "./views/routes/Terms";
import Cookies from "./views/routes/Cookies";

import "./App.css";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<StartView />} />
         <Route path="/terms" element={<Terms />} />
         <Route path="/cookies" element={<Cookies />} />
        <Route path="*" element={<StartView />} />
      </Routes>
      <Listeners />
      <FirebaseListeners />
    </div>
  );
}

export default App;
