import React, { useEffect, useState } from "react";
import { useDeviceState, useScrollStates } from "./globalStates";
import Snackbar from "@mui/material/Snackbar";
import { Button } from "@mui/material";

export default function Listeners() {
  const [gdprOpen, setGdprOpen] = useState(false);
  const deviceState = useDeviceState((state) => state);
  const scrollState = useScrollStates((state) => state);

  const checkIfMobile = () => {
    if (window.innerWidth > window.innerHeight) {
      deviceState.setMobile(false);
    } else {
      deviceState.setMobile(true);
    }
  };

  useEffect(() => {
    const onResize = () => checkIfMobile();

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  const gdprCheck = () => {
    if (
      localStorage.getItem("gdpr") === null ||
      localStorage.getItem("gdpr") === undefined ||
      localStorage.getItem("gdpr") === "false" ||
      localStorage.getItem("gdpr") === ""
    ) {
      setGdprOpen(true);
    }
  };

  const onGdprClose = () => {
    localStorage.setItem("gdpr", "true");
    setGdprOpen(false);
  };

  useEffect(() => {
    checkIfMobile();
  }, []);

  useEffect(() => {
    gdprCheck();
  }, []);

  // scroll listener that updates the scroll state when scroll start
  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    const onStop = () => {
      scrollState.setIsScrolling(false);
    };

    /*const onScrollY = () => {
      if (window.scrollY > window.innerHeight && !scrollState.toTop) {
        scrollState.setToTop(true);
      } else if (window.scrollY < window.innerHeight && scrollState.toTop) {
        scrollState.setToTop(false);
      }
    };*/

    const onScroll = () => {
      clearTimeout(timeoutId);
      scrollState.setToTop(window.scrollY);
      if (!scrollState.isScrolling) {
        scrollState.setIsScrolling(true);
      }
      timeoutId = setTimeout(onStop, 300);
      //onScrollY();
    };

    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, [scrollState.toTop]);

  return (
    <>
      <Snackbar
        open={gdprOpen}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "#fffbf3",
            color: "#39393c",
            fontSize: "14px",
            fontWeight: 600,
            border: "2px solid #ffffff",
            textAlign: "left !important",
            '@media (min-width: 600px)': {
                marginRight: "24px !important",
                maxWidth: "360px",
                },
            '@media (min-width: 900px)': {
                maxWidth: "500px",
                },

          },
        }}
        message="We use cookies to improve your experience on our site. By using our site, you agree to our use of cookies."
        action={
          <React.Fragment>
            <Button
            variant="text"
              onClick={onGdprClose}
              color="inherit"
            >
              Accept
            </Button>
          </React.Fragment>
        }
      />
    </>
  );
}
