import ScribbleLogo from "../../../components/ui/ScribbleLogo";
import AchievementsPreview from "./components/AchievementsPreview";

import "./Points.css";

export default function Points() {
  return (
    <div className="PointsMobile">
      <div className="PointsInnerMobile">
        <p className="StartInnerTitleMobile" style={{width: '70%'}}>
          Earn achievements and points based on your activity.
        </p>
        <ScribbleLogo />
        <p className="StartInnerTextMobile" style={{width: '65%'}}>
          GATH3R rewards it’s users that contribute to the ever expanding
          database of Web3 projects. List, rate, and review your favourite Web3
          products and gain GATH3R points. Compete on the leader boards, and
          follow top contributors.
        </p>
      </div>
        <AchievementsPreview />
    </div>
  );
}
