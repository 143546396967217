import React from "react";
import styles from "../ui.module.css";

function InstagramLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 20"
      viewBox="0 0 500 500"
      className={styles.socialsLogoSvg}
    >
      <g fill="#3a3a3d">
        <path
          d="M750 500c-138.07 0-250 111.93-250 250s111.93 250 250 250 250-111.93 250-250-111.93-250-250-250zm0 480.83C622.61 980.75 519.4 877.41 519.49 750s103.42-230.59 230.82-230.5 230.6 103.43 230.51 230.82A230.66 230.66 0 01750 980.83z"
          transform="translate(-500 -500)"
        ></path>
        <path
          d="M826.83 614.67H673.17A57.17 57.17 0 00615.5 671v158.17a57 57 0 0057.66 56.33h153.51a57 57 0 0057.66-56.33V671a57 57 0 00-57.5-56.33zm34.5 214.5A33.33 33.33 0 01828 862.52H673.17a33.33 33.33 0 01-34.48-32.15V671A33.33 33.33 0 01672 637.65h154.69a33.33 33.33 0 0134.48 32.14v1.19z"
          transform="translate(-500 -500)"
        ></path>
        <path
          d="M750 679.67A70.33 70.33 0 10820.33 750 70.33 70.33 0 00750 679.67zm0 116.66a47 47 0 1147-47v.67a47.16 47.16 0 01-47 47z"
          transform="translate(-500 -500)"
        ></path>
        <circle cx="325.5" cy="176.17" r="16.5"></circle>
      </g>
    </svg>
  );
}

export default InstagramLogo;
