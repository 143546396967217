import React, { useState, useRef } from "react";
import Avatar from "@mui/material/Avatar";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import moment from "moment";
import styles from "./news.module.css";
import DOMPurify from "dompurify";

interface ListingDialogProps {
  listing: any;
}

moment().format();

export default function Rss3Item({ listing }: ListingDialogProps) {
  const ref = useRef<HTMLDivElement>(null);
  const [isExpanded, setIsExpanded] = useState(false);

  const onLessClick = () => {
    if (!isExpanded) {
      setIsExpanded(true);
      return;
    }
    setIsExpanded(false);
    if (ref.current) {
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };
  return (
    <div
      className={styles.rss3Listing}
      style={{ maxHeight: isExpanded ? "1500px" : "300px" }}
      ref={ref}
    >
      <div className={styles.rss3ListingHeader}>
        {listing.img !== "" ? (
          <Avatar alt={"logo"} src={listing.img} sx={avatarStyleLogo} />
        ) : (
          <Avatar sx={avatarStyle} alt={"logo"}>
            {listing.platform === "Crossbell" ? "Cb" : "Mi"}
          </Avatar>
        )}
        <div className={styles.rss3ListingHeaderInner}>
          <p className={styles.rss3ListingTitle}>{listing.title}</p>
          <p className={styles.rss3ListingSubtitle}>
            {`${listing.handle} @ ${listing.platform} · ${moment(
              listing.timestamp
            ).fromNow()}`}
          </p>
        </div>
      </div>
      {listing.isMd ? (
        <div
          className={styles.markdownOuter}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(listing.body, {
              FORBID_ATTR: ["style"],
              FORBID_TAGS: ["style", "a"],
            }),
          }}
        ></div>
      ) : (
        <p className={styles.rss3ListingText}>{listing.body}</p>
      )}
      <div className={styles.rssListingBottomGradient}></div>
      <div className={styles.rssListingFooter}>
        <div className={styles.rssListingFooterInner} onClick={onLessClick}>
          <p className={styles.rssListingFooterText}>
            {isExpanded ? "Less" : "More"}
          </p>
          {isExpanded ? (
            <ExpandLessIcon
              color="inherit"
              sx={{ fontSize: "20px", marginLeft: "3px" }}
            />
          ) : (
            <ExpandMoreIcon
              color="inherit"
              sx={{ fontSize: "20px", marginLeft: "3px" }}
            />
          )}
        </div>
        <a
          href={listing.link}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.rssListingFooterInner}
        >
          <p className={styles.rssListingFooterText}>Go to article</p>
          <OpenInNewIcon
            color="inherit"
            sx={{ fontSize: "20px", marginLeft: "5px" }}
          />
        </a>
      </div>
    </div>
  );
}

const avatarStyle = {
  bgcolor: "#bbdcc5",
  color: "#39393c",
  width: "30px",
  height: "30px",
  fontSize: "15px",
  borderRadius: "4px",
  marginTop: "4px",
  "@media (min-width: 350px)": {
    width: "40px",
    height: "40px",
    fontSize: "20px",
  },
  "@media (min-width: 550px)": {
    width: "50px",
    height: "50px",
    fontSize: "25px",
  },
};

const avatarStyleLogo = {
  width: "30px",
  height: "30px",
  fontSize: "15px",
  borderRadius: "4px",
  marginTop: "2px",
  "@media (min-width: 350px)": {
    width: "40px",
    height: "40px",
    fontSize: "20px",
  },
  "@media (min-width: 550px)": {
    width: "50px",
    height: "50px",
    fontSize: "25px",
  },
};
