import React, { useState, useEffect } from "react";
import algoliasearch from "algoliasearch/lite";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import ButtonBase from "@mui/material/ButtonBase";
import styles from "./listings.module.css";
import SearchIcon from "@mui/icons-material/Search";
import { CircularProgress } from "@mui/material";
import {
  useListingDialogState,
  useMediumState,
  useRss3State,
  useRssState,
} from "../utils/globalStates";
import DOMPurify from "dompurify";
import { materialFormsStylesSearch } from "../theme/materialGlobals";
import moment from "moment";

import { algoliaId, algoliaKey } from "../../.config/config";

moment().format();

const searchClient = algoliasearch(algoliaId, algoliaKey);
const index = searchClient.initIndex("gath3r_listings");

export default function SearchInput() {
  const { setListingId } = useListingDialogState();
  const { setIsFetched } = useRssState();
  const { setIsFetched: setIsFetchedRss3 } = useRss3State();
  const { setIsFetched: setIsFetchedMedium } = useMediumState();
  const [query, setQuery] = useState("");
  const [results, setResults] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let tempArray: any[] = [];
    if (query !== "") {
      setLoading(true);
      index
        .search(query)
        .then((res) => {
          for (let i = 0; i < res.hits.length; i++) {
            const tempListing = res.hits[i];
            tempArray.push({ data: tempListing, type: "query" });
          }

          // Get last visited listings from local storage

          let lastVisited = localStorage.getItem("visitedListings");
          if (lastVisited !== null && lastVisited !== undefined) {
            let lastVisitedParsed = JSON.parse(lastVisited);
            if (lastVisitedParsed.length > 0) {
              tempArray.push({ data: "Last Visited", type: "title" });
              for (let i = 0; i < lastVisitedParsed.length; i++) {
                const tempListingVisited = lastVisitedParsed[i];
                tempArray.push({
                  data: tempListingVisited,
                  type: "lastVisited",
                });
              }
            }
          }
          setResults(tempArray);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  }, [query]);

  const onResultClick = (id: string) => {
    setListingId(id);
    setIsFetched(false);
    setIsFetchedRss3(false);
    setIsFetchedMedium(false);
  };

  // Replace <em> tags with <strong> tags
  const replaceEm = (str: string) => {
    return str.replace(/<em>/g, "<strong>").replace(/<\/em>/g, "</strong>");
  };

  useEffect(() => {
    let tempArray: any[] = [];
    let lastVisited = localStorage.getItem("visitedListings");
    if (lastVisited !== null && lastVisited !== undefined) {
      let lastVisitedParsed = JSON.parse(lastVisited);
      if (lastVisitedParsed.length > 0) {
        tempArray.push({ data: "Last Visited", type: "title" });
        for (let i = 0; i < lastVisitedParsed.length; i++) {
          const tempListingVisited = lastVisitedParsed[i];
          tempArray.push({
            data: tempListingVisited,
            type: "lastVisited",
          });
        }
      }
    }
    setResults(tempArray);
  }, []);

  return (
    <Autocomplete
      freeSolo
      fullWidth
      sx={materialFormsStylesSearch}
      size="small"
      filterOptions={(x) => x}
      loading={loading}
      getOptionLabel={(option: any) =>
        option.type === "title" ? option.data : option.data.name
      }
      renderOption={(props, option: any) => {
        return option.type === "title" ? (
          <p key={option.data} className={styles.autocompleteResultHeader}>
            {" "}
            {option.data}
          </p>
        ) : option.type === "lastVisited" ? (
          <ButtonBase
            key={option.data.visited}
            onClick={() => {
              onResultClick(option.data.id);
            }}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
              justifyContent: "space-between",
              width: "100%",
              padding: "10px",
              backgroundColor: "#fff",
            }}
          >
            <p className={styles.autocompleteResultTitle}>{option.data.name}</p>
            <p className={styles.autocompleteResultText}>
              {moment(option.data.visited).fromNow()}
            </p>
          </ButtonBase>
        ) : (
          <ButtonBase
            key={option.data.id}
            onClick={() => {
              onResultClick(option.data.id);
            }}
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
              justifyContent: "space-between",
              width: "100%",
              padding: "10px",
              backgroundColor: "#fff",
            }}
          >
            <p
              className={styles.autocompleteResultTitle}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  replaceEm(option.data._highlightResult.name.value),
                  {
                    FORBID_ATTR: ["style"],
                    FORBID_TAGS: ["style", "a"],
                  }
                ),
              }}
            ></p>
            <p
              className={styles.autocompleteResultText}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  replaceEm(
                    "in " + option.data._highlightResult.category.value
                  ),
                  {
                    FORBID_ATTR: ["style"],
                    FORBID_TAGS: ["style", "a"],
                  }
                ),
              }}
            ></p>
          </ButtonBase>
        );
      }}
      options={results}
      renderInput={(params) => (
        <TextField
          {...params}
          value={query}
          onChange={(event) => {
            setQuery(event.target.value);
          }}
          placeholder="Search for..."
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <React.Fragment>
                <SearchIcon
                  color="inherit"
                  sx={{ fontSize: "20px", marginLeft: "5px", opacity: 0.6 }}
                />
              </React.Fragment>
            ),
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={"20px"} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
