import React from "react";
import IconButton from "@mui/material/IconButton";
import "./Routes.css";
import { ArrowBackIos } from "@mui/icons-material";

export default function Cookies() {
  return (
    <div className="RoutesOuter">
      <div className="RoutesHeader">
        <IconButton
          aria-label="menu"
          color="inherit"
          sx={{ marginLeft: "10px" }}
          onClick={() => {
            window.history.back();
          }}
        >
          <ArrowBackIos />
        </IconButton>
      </div>
      <div className="RoutesInner">
        <p className="RoutesTitle">GATH3R COOKIES NOTICE</p>
        <p className="RoutesSubTitle">Last Updated: [JULY 2024]</p>
        <p className="RoutesText">
          GATH3R uses cookies and similar tracking technologies to enhance your
          experience on our platform. Cookies are small text files that are
          placed on your device when you visit our website. They help us
          remember your preferences, analyze how you use our platform, and
          personalize content.
        </p>

        <p className="RoutesTextTitle">Types of Cookies We Use</p>
        <p className="RoutesText">
          <b>Essential Cookies: </b>These cookies are necessary for the basic
          functioning of our platform, such as enabling you to log in to your
          account and navigate between pages.
        </p>
        <p className="RoutesText">
          <b>Performance Cookies: </b>These cookies help us understand how you
          interact with our platform by collecting information about the pages
          you visit, the links you click, and other actions you take. We use
          this information to improve the performance and usability of our
          platform.
        </p>
        <p className="RoutesText">
          <b>Functional Cookies: </b>These cookies allow our platform to
          remember choices you make, such as your preferred language or region.
          They may also be used to provide you with enhanced features and
          personalized content.
        </p>
        <p className="RoutesText">
          <b>Targeting/Advertising Cookies: </b>These cookies are used to
          deliver advertisements that are relevant to your interests. They may
          also be used to limit the number of times you see an advertisement and
          measure the effectiveness of advertising campaigns.
        </p>
        <p className="RoutesTextTitle">Your Cookie Choices</p>
        <p className="RoutesText">
          You have the right to choose whether or not to accept cookies. You can
          control and manage cookies through your browser settings. Most
          browsers allow you to block or delete cookies, but this may affect
          your ability to use certain features of our platform.
          <br />
          <br />
          For more information about how we use cookies and your choices, please
          refer to our Privacy Policy.
          <br />
          <br />
          By continuing to use our platform, you consent to our use of cookies
          in accordance with this Cookie Notice and our Privacy Policy.
        </p>
      </div>
    </div>
  );
}
