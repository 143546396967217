import React from "react";
import './ui.css';

function Gath3rLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="128"
      height="25"
      fill="none"
      viewBox="0 0 128 25"
      className="mainLogo"
    >
      <g fill="#1A1A1A" clipPath="url(#clip0_3_8035)">
        <path d="M25.586 11.682c0 7.787-5.11 13.32-12.596 13.32C5.503 25.002.25 19.759.25 12.52.25 5.282 5.564 0 12.93 0c6.268 0 11.396 3.903 12.407 9.434h-6.904C17.567 7.43 15.428 6.14 12.99 6.14c-3.582 0-5.997 2.577-5.997 6.38 0 3.802 2.375 6.341 5.997 6.341 2.514 0 4.67-1.21 5.422-3.045h-5.939v-4.134h13.113v-.001zM40.616 20.534H32.14l-1.325 3.864h-7.06L33.15.602h6.439l9.598 23.796h-7.254l-1.318-3.864h.002zM36.365 8.03l-2.62 7.373h5.263L36.365 8.03zM65.288 6.505h-6.495V24.4h-6.616V6.505H45.68V.602h19.608v5.903zM86.985.602v23.796h-6.646v-9.135h-7.175v9.135h-6.646V.602h6.646v8.585h7.175V.601h6.646zM100.414 9.144c3.35.837 5.494 3.537 5.494 7.138 0 4.73-3.683 8.116-8.811 8.116-5.13 0-8.476-3.261-8.615-8.077h5.998c.07 1.64 1.095 2.73 2.632 2.73 1.536 0 2.717-1.17 2.717-2.87 0-1.7-1.16-2.754-2.98-2.754h-3.743V12.13l3.805-6.227h-7.844V.602h16.037V1.67l-4.692 7.472.002.002zM116.517 17.119h-2.388V24.4h-6.587V.602h10.363c5.155 0 8.891 3.447 8.891 8.421 0 2.92-1.432 5.314-3.795 6.721l4.747 8.654h-7.352l-3.88-7.28h.001zm-2.388-5.523h3.009c1.842 0 2.902-1.104 2.902-2.59 0-1.487-1.06-2.577-2.902-2.577h-3.009v5.167z"></path>
      </g>
      <defs>
        <clipPath id="clip0_3_8035">
          <path
            fill="#fff"
            d="M0 0H127.5V25H0z"
            transform="translate(.25)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Gath3rLogo;
