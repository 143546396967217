import React, { useState, useEffect, useRef } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Avatar from "@mui/material/Avatar";
import { LazyLoadImg } from "../../../../components/ui/LazyLoadImg";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import IconButton from "@mui/material/IconButton";
import SearchInput from "../../../../components/listings/SearchInput";
import { TransitionProps } from "@mui/material/transitions";
import { logEvent } from "firebase/analytics";
import Skeleton from "@mui/material/Skeleton";
import { collection, query, getDocs, orderBy, limit } from "firebase/firestore";
import {
  firestoreDb,
  firebaseAnalytics,
} from "../../../../components/firebase/firebase";
import moment from "moment";
import ListingDialog from "../../../../components/listings/ListingDialog";
import { useListingDialogState } from "../../../../components/utils/globalStates";
import {
  useRss3State,
  useMediumState,
  useRssState,
} from "../../../../components/utils/globalStates";

import "./ListingsComp.css";

moment().format();

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

let isFetching = false;

export default function ListingTable() {
  const listingDialogData = useListingDialogState();
  const rss3Data = useRss3State();
  const mediumData = useMediumState();
  const rssData = useRssState();
  const listingRef = useRef("");
  const [activeTab, setActiveTab] = useState("latest");
  const [listingsTrending, setListingsTrending] = useState<any[]>([]);
  const [listingsLatest, setListingsLatest] = useState<any[]>([]);
  const [listingsRating, setListingsRating] = useState<any[]>([]);
  const [listingsViews, setListingsViews] = useState<any[]>([]);
  const [listingsShow, setListingsShow] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    listingRef.current = listingDialogData.listingId;
  }, [listingDialogData.listingId]);

  useEffect(() => {
    if (activeTab === "trending" && listingsTrending.length > 0) {
      setListingsShow(listingsTrending);
      return;
    }
    if (activeTab === "latest" && listingsLatest.length > 0) {
      setListingsShow(listingsLatest);
      return;
    }
    if (activeTab === "rating" && listingsRating.length > 0) {
      setListingsShow(listingsRating);
      return;
    }
    if (activeTab === "views" && listingsViews.length > 0) {
      setListingsShow(listingsViews);
      return;
    }
    if (isFetching === false) {
      isFetching = true;
      setLoading(true);
      let q = query(
        collection(firestoreDb, "listings"),
        orderBy("createdAt", "desc"),
        limit(10)
      );
      if (activeTab === "trending") {
        q = query(
          collection(firestoreDb, "listings"),
          //where("trendingUpdated", ">", Date.now() - (60*60*48*1000)),
          orderBy("trendingHour", "desc"),
          limit(10)
        );
      } else if (activeTab === "rating") {
        q = query(
          collection(firestoreDb, "listings"),
          orderBy("rating", "desc"),
          limit(10)
        );
      } else if (activeTab === "views") {
        q = query(
          collection(firestoreDb, "listings"),
          orderBy("viewsTotal", "desc"),
          limit(10)
        );
      }

      let tempArray: any[] = [];
      getDocs(q)
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            tempArray.push(doc.data());
          });
          if (activeTab === "trending") {
            setListingsTrending(tempArray);
          } else if (activeTab === "rating") {
            setListingsRating(tempArray);
          } else if (activeTab === "views") {
            setListingsViews(tempArray);
          } else {
            setListingsLatest(tempArray);
          }
          setListingsShow(tempArray);
          isFetching = false;
          setLoading(false);
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
          isFetching = false;
          setLoading(false);
        });
    }
  }, [activeTab]);

  const onListingClick = (id: string) => {
    listingDialogData.setListingId(id);
    setTimeout(() => {
      if (id.toString() === listingRef.current.toString()) {
        logEvent(firebaseAnalytics, "listing_click", {
          listing_id: id,
        });
      }
    }, 5000);
  };

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const listingIdParam = urlParams.get("listing");
    if (listingIdParam !== null) {
      listingDialogData.setListingId(listingIdParam);
      logEvent(firebaseAnalytics, "listing_share_visit", {
        listing_id: listingIdParam,
      });
    }
  }, []);

  const onCloseDialog = () => {
    listingDialogData.setListingId("");
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    rss3Data.setListings([]);
    mediumData.setListings([]);
    rssData.setListings([]);
    rss3Data.setIsFetched(false);
    mediumData.setIsFetched(false);
    rssData.setIsFetched(false);
    if (urlParams.size > 0) {
      window.history.replaceState({}, document.title, window.location.pathname);
    }
  };

  return (
    <div className="listingTableMobile">
      <div className="listingTableTopMobile">
        <p className="listingTableTitleMobile">Search GATH3R</p>
        <p className="listingTableSubMobile">
          Find what you are looking for and so much more.
        </p>
        <div className="listingTableSubMobile" style={{marginTop: '-5px'}}>
        <SearchInput />
        </div>
      </div>
      <div className="listingTableButtonsMobile">
        <p className="listingTableSubMobile" style={{ width: "unset", marginBottom: '0px' }}>
          Sort by:
        </p>
        <p
          className="listingTableButtonMobile"
          style={{
            backgroundColor: activeTab === "latest" ? "#FFF2AC" : "transparent",
            boxShadow:
              activeTab === "latest"
                ? "2px 2px 5px rgba(35, 35, 35, 0.2)"
                : "none",
          }}
          onClick={() => setActiveTab("latest")}
        >
          Latest
        </p>
        <p
          className="listingTableButtonMobile"
          style={{
            backgroundColor:
              activeTab === "trending" ? "#FFF2AC" : "transparent",
            boxShadow:
              activeTab === "trending"
                ? "2px 2px 5px rgba(35, 35, 35, 0.2)"
                : "none",
          }}
          onClick={() => setActiveTab("trending")}
        >
          Trending
        </p>
        <p
          className="listingTableButtonMobile"
          style={{
            backgroundColor: activeTab === "views" ? "#FFF2AC" : "transparent",
            boxShadow:
              activeTab === "views"
                ? "2px 2px 5px rgba(35, 35, 35, 0.2)"
                : "none",
          }}
          onClick={() => setActiveTab("views")}
        >
          Views
        </p>
      </div>
      <div className="listingTableInnerMobile">
        {loading ? (
          <>
            {Array.from({ length: 10 }, (_, i) => (
              <div className="ProjectPreviewOuterMobile" key={i} 
              style={{ minHeight: "42px", boxShadow: '2px 2px 5px rgba(35, 35, 35, 0.2)', backgroundColor: '#ffffff' }}>
                <Skeleton
                  variant="circular"
                  sx={{
                    marginTop: "5px",
                    marginBottom: "5px",
                    width: "30px",
                    height: "30px",
                    backgroundColor: "#F5E3FF",
                  }}
                />
                <div className="ProjectPreviewInnerMobile">
                  <Skeleton
                    variant="text"
                    sx={{ fontSize: "8px", width: "30%", marginTop: "2px" }}
                  />
                  <Skeleton
                    variant="text"
                    sx={{ fontSize: "7px", width: "100%" }}
                  />
                  <Skeleton
                    variant="text"
                    sx={{ fontSize: "7px", width: "100%", marginBottom: "2px" }}
                  />
                </div>
              </div>
            ))}
          </>
        ) : (
          listingsShow.map((listing) => (
            <div
              key={listing.id}
              className="ProjectPreviewOuterMobile"
              onClick={() => onListingClick(listing.id)}
              style={{ cursor: "pointer", minHeight: "42px", boxShadow: '2px 2px 5px rgba(35, 35, 35, 0.2)', backgroundColor: '#ffffff' }}
            >
              {listing.logo !== "" &&
              listing.logo !== undefined &&
              listing.logo !== null ? (
                <div className="ProjectPreviewImgMobile">
                  <LazyLoadImg
                    // @ts-ignore
                    imgUrl={listing.logo}
                  />
                </div>
              ) : (
                <Avatar
                  sx={{
                  bgcolor: "#F5E3FF",
                  color: "##39393c",
                  width: "30px",
                  height: "30px",
                  borderRadius: "50%",
                  fontSize: "12px",
                }}
                  alt={listing.name}
                >
                  {listing.name.substr(0, 2)}
                </Avatar>
              )}
              <div
                className="ProjectPreviewInnerMobile"
                style={{ paddingTop: "5px", marginBottom: "5px" }}
              >
                <p className="ProjectPreviewTitleMobile">{listing.name}</p>
                <p className="ProjectPreviewTextMobile">{listing.description}</p>
              </div>
            </div>
          ))
        )}
      </div>
      <Dialog
        onClose={onCloseDialog}
        open={listingDialogData.listingId !== ""}
        fullScreen={true}
        TransitionComponent={Transition}
      >
        <div className="dialogInnerMobile">
          <div className="appDialogCloseMobile">
            <div className="appHeaderLogoMobile"></div>
            <IconButton
              aria-label="menu"
              color="inherit"
              onClick={onCloseDialog}
            >
              <CloseIcon />
            </IconButton>
          </div>
          {listingDialogData.listingId !== "" ? <ListingDialog /> : null}
        </div>
      </Dialog>
    </div>
  );
}
