import { initializeApp } from "firebase/app";
import { getAnalytics, isSupported } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getDatabase } from "firebase/database";
import { getFunctions } from "firebase/functions";
import {
  initializeAppCheck,
  ReCaptchaEnterpriseProvider,
} from "firebase/app-check";
import { recaptchaKey } from "../../.config/config";
import { firebaseConfig } from "../../.config/config";

export const firebaseApp = initializeApp(firebaseConfig);
export const firebaseAnalytics = getAnalytics(firebaseApp);
export const firestoreDb = getFirestore(firebaseApp);
export const firebaseDb = getDatabase(firebaseApp);
export const firebaseFunctions = getFunctions(firebaseApp);
export const firebaseAppCheck = initializeAppCheck(firebaseApp, {
  provider: new ReCaptchaEnterpriseProvider(recaptchaKey),
  isTokenAutoRefreshEnabled: true,
});
