import React from "react";
import styles from "../ui.module.css";

function MediumLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 20"
      viewBox="0 0 500 499.93"
      className={styles.socialsLogoSvg}
    >
      <g fill="#3a3a3d">
        <path
          d="M987.67 672.65c42.7 131.27-29.09 272.29-160.36 315s-272.29-29.1-315-160.36 29.09-272.29 160.36-315a249.92 249.92 0 01315 160.36zm-237.6-150.12c-125.61.17-227.3 102.14-227.13 227.75s102.14 227.3 227.75 227.12S978 875.27 977.82 749.65a227.43 227.43 0 00-227.75-227.12z"
          transform="translate(-500 -500.03)"
        ></path>
        <path
          d="M772.08 751.51a98.35 98.35 0 00-98.07-99c-49.52-.39-98.28 39.16-98.33 98.85s48.58 99.29 98.2 99a98.33 98.33 0 0098.2-98.85zM783 751.29a158.4 158.4 0 005.43 42.85c4.09 14.94 9.94 29.06 21.17 40.21 13.79 13.68 30.77 13.6 44.58-.07a70.66 70.66 0 0016-25.38c13.18-34.32 13.8-69.19 3.75-104.33-3.84-13.46-9.74-26-19.88-36.05-13.65-13.52-30.53-13.52-44.28-.21A69.56 69.56 0 00795.13 690c-8.85 19.44-11.97 40.08-12.13 61.29zM891.55 753.6a281.57 281.57 0 004.56 55.34c1.44 7.61 3.15 15.19 7.2 21.94 2.94 4.88 6.71 4.83 9.81 0a39.36 39.36 0 004.37-10.48c3.67-12.67 5.23-25.68 6.3-38.79a347.17 347.17 0 00-3-85.08c-1.4-8.48-3.14-17-7.67-24.51-3-4.9-6.79-4.85-9.8 0a47.81 47.81 0 00-5.46 14.26c-5.19 21.41-6.42 43.22-6.31 67.32z"
          transform="translate(-500 -500.03)"
        ></path>
      </g>
    </svg>
  );
}

export default MediumLogo;
