import Skeleton from "@mui/material/Skeleton";
import Avatar from "@mui/material/Avatar";
import { LazyLoadImg } from "../../../../components/ui/LazyLoadImg";
import {
  useProjectCounterState,
  userReviewsCounterState,
  useRatingsCounterState,
  useProjectsPreviewState,
  useReviewsPreviewState,
  useRatingsPreviewState,
} from "../../../../components/utils/globalStates";
import Rating from "@mui/material/Rating";

import "./StartComp.css";

export default function StartStats() {
  const projectCounter = useProjectCounterState((state) => state);
  const ratingsCounter = useRatingsCounterState((state) => state);
  const reviewsCounter = userReviewsCounterState((state) => state);
  const projectsPreview = useProjectsPreviewState((state) => state);
  const reviewsPreview = useReviewsPreviewState((state) => state);
  const ratingsPreview = useRatingsPreviewState((state) => state);
  return (
    <div className="StartStatsMobile">
      <div className="StartStatsInnerMobile">
        <p className="StartStatsTitleMobile">
          {new Intl.NumberFormat().format(projectCounter.count)}{" "}
          <span style={{ fontSize: "45%", fontWeight: 500 }}>
            Listed Projects
          </span>
        </p>
        {projectsPreview.isFetched ? (
          <div className="ProjectPreviewOuterMobile">
            {projectsPreview.documents[0].logo !== null &&
            projectsPreview.documents[0].logo !== undefined &&
            projectsPreview.documents[0].logo !== "" ? (
              <div className="ProjectPreviewImgMobile">
                <LazyLoadImg
                  // @ts-ignore
                  imgUrl={projectsPreview.documents[0].logo}
                />
              </div>
            ) : (
              <Avatar
                sx={{
                  bgcolor: "#F5E3FF",
                  color: "##39393c",
                  width: "30px",
                  height: "30px",
                  borderRadius: "50%",
                  fontSize: "12px",
                }}
                alt={projectsPreview.documents[0].name}
              >
                {projectsPreview.documents[0].name.substr(0, 2)}
              </Avatar>
            )}
            <div className="ProjectPreviewInnerMobile">
              <p className="ProjectPreviewTitleMobile">
                {projectsPreview.documents[0].name}
              </p>
              <p className="ProjectPreviewTextMobile">
                {projectsPreview.documents[0].description}
              </p>
            </div>
          </div>
        ) : (
          <div className="ProjectPreviewOuterMobile">
            <Skeleton
              variant="circular"
              sx={{ width: "28px", height: "28px", backgroundColor: "#F5E3FF" }}
            />
            <div className="ProjectPreviewInnerMobile">
              <Skeleton variant="text" sx={{ fontSize: "8px", width: "30%" }} />
              <Skeleton
                variant="text"
                sx={{ fontSize: "7px", width: "100%" }}
              />
              <Skeleton
                variant="text"
                sx={{ fontSize: "7px", width: "100%" }}
              />
            </div>
          </div>
        )}
        {projectsPreview.isFetched ? (
          <div className="ProjectPreviewOuterMobile">
            {projectsPreview.documents[1].logo !== null &&
            projectsPreview.documents[1].logo !== undefined &&
            projectsPreview.documents[1].logo !== "" ? (
              <div className="ProjectPreviewImgMobile">
                <LazyLoadImg
                  // @ts-ignore
                  imgUrl={projectsPreview.documents[1].logo}
                />
              </div>
            ) : (
              <Avatar
                sx={{
                  bgcolor: "#F5E3FF",
                  color: "##39393c",
                  width: "30px",
                  height: "30px",
                  borderRadius: "50%",
                  fontSize: "12px",
                }}
                alt={projectsPreview.documents[1].name}
              >
                {projectsPreview.documents[1].name.substr(0, 2)}
              </Avatar>
            )}
            <div className="ProjectPreviewInnerMobile">
              <p className="ProjectPreviewTitleMobile">
                {projectsPreview.documents[1].name}
              </p>
              <p className="ProjectPreviewTextMobile">
                {projectsPreview.documents[1].description}
              </p>
            </div>
          </div>
        ) : (
          <div className="ProjectPreviewOuterMobile">
            <Skeleton
              variant="circular"
              sx={{ width: "28px", height: "28px", backgroundColor: "#F5E3FF" }}
            />
            <div className="ProjectPreviewInnerMobile">
              <Skeleton variant="text" sx={{ fontSize: "8px", width: "30%" }} />
              <Skeleton
                variant="text"
                sx={{ fontSize: "7px", width: "100%" }}
              />
              <Skeleton
                variant="text"
                sx={{ fontSize: "7px", width: "100%" }}
              />
            </div>
          </div>
        )}
        {projectsPreview.isFetched ? (
          <div className="ProjectPreviewOuterMobile">
            {projectsPreview.documents[2].logo !== null &&
            projectsPreview.documents[2].logo !== undefined &&
            projectsPreview.documents[2].logo !== "" ? (
              <div className="ProjectPreviewImgMobile">
                <LazyLoadImg
                  // @ts-ignore
                  imgUrl={projectsPreview.documents[2].logo}
                />
              </div>
            ) : (
              <Avatar
                sx={{
                  bgcolor: "#F5E3FF",
                  color: "##39393c",
                  width: "30px",
                  height: "30px",
                  borderRadius: "50%",
                  fontSize: "12px",
                }}
                alt={projectsPreview.documents[2].name}
              >
                {projectsPreview.documents[2].name.substr(0, 2)}
              </Avatar>
            )}
            <div className="ProjectPreviewInnerMobile">
              <p className="ProjectPreviewTitleMobile">
                {projectsPreview.documents[2].name}
              </p>
              <p className="ProjectPreviewTextMobile">
                {projectsPreview.documents[2].description}
              </p>
            </div>
          </div>
        ) : (
          <div className="ProjectPreviewOuterMobile">
            <Skeleton
              variant="circular"
              sx={{ width: "28px", height: "28px", backgroundColor: "#F5E3FF" }}
            />
            <div className="ProjectPreviewInnerMobile">
              <Skeleton variant="text" sx={{ fontSize: "8px", width: "30%" }} />
              <Skeleton
                variant="text"
                sx={{ fontSize: "7px", width: "100%" }}
              />
              <Skeleton
                variant="text"
                sx={{ fontSize: "7px", width: "100%" }}
              />
            </div>
          </div>
        )}
      </div>
      <div className="StartStatsInnerMobile">
        <p className="StartStatsTitleMobile">
          {new Intl.NumberFormat().format(reviewsCounter.count)}{" "}
          <span style={{ fontSize: "45%", fontWeight: 500 }}>Reviews</span>
        </p>
        {reviewsPreview.isFetched ? (
          <div className="ReviewsPreviewOuterMobile">
            <div className="ReviewsPreviewInnerMobile">
              {reviewsPreview.documents[0].logo !== null &&
              reviewsPreview.documents[0].logo !== undefined &&
              reviewsPreview.documents[0].logo !== "" ? (
                <div className="ProjectPreviewImgMobile">
                  <LazyLoadImg
                    // @ts-ignore
                    imgUrl={reviewsPreview.documents[0].logo}
                  />
                </div>
              ) : (
                <Avatar
                sx={{
                  bgcolor: "#F5E3FF",
                  color: "##39393c",
                  width: "30px",
                  height: "30px",
                  borderRadius: "50%",
                  fontSize: "12px",
                }}
                  alt={reviewsPreview.documents[0].listingName}
                >
                  {reviewsPreview.documents[0].listingName.substr(0, 2)}
                </Avatar>
              )}
              <div className="ProjectPreviewInnerMobile">
                <p className="ProjectPreviewTitleMobile">
                  {reviewsPreview.documents[0].listingName}
                </p>
                <p className="ReviewsPreviewSubMobile">
                  {reviewsPreview.documents[0].title}
                </p>
              </div>
            </div>
            <p className="ReviewsPreviewTextMobile">
              {reviewsPreview.documents[0].text}
            </p>
          </div>
        ) : (
          <div className="ReviewsPreviewOuterMobile">
            <div className="ReviewsPreviewInnerMobile">
              <Skeleton
                variant="circular"
                sx={{
                  width: "28px",
                  height: "28px",
                  backgroundColor: "#F5E3FF",
                }}
              />
              <div className="ProjectPreviewInnerMobile">
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "8px", width: "30%" }}
                />
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "7px", width: "100%" }}
                />
              </div>
            </div>
            <Skeleton
              variant="text"
              sx={{ fontSize: "7px", width: "100%", marginTop: "20px" }}
            />
            <Skeleton variant="text" sx={{ fontSize: "7px", width: "100%" }} />
            <Skeleton variant="text" sx={{ fontSize: "7px", width: "100%" }} />
            <Skeleton variant="text" sx={{ fontSize: "7px", width: "100%" }} />
            <Skeleton variant="text" sx={{ fontSize: "7px", width: "100%" }} />
            <Skeleton variant="text" sx={{ fontSize: "7px", width: "100%" }} />
            <Skeleton variant="text" sx={{ fontSize: "7px", width: "100%" }} />
            <Skeleton variant="text" sx={{ fontSize: "7px", width: "100%" }} />
          </div>
        )}
      </div>
      <div className="StartStatsInnerMobile">
        <p className="StartStatsTitleMobile">
          {new Intl.NumberFormat().format(ratingsCounter.count)}{" "}
          <span style={{ fontSize: "45%", fontWeight: 500 }}>Ratings</span>
        </p>
        {ratingsPreview.isFetched ? (
          <div className="RatingsPreviewOuterMobile">
            {ratingsPreview.documents[0].logo !== null &&
            ratingsPreview.documents[0].logo !== undefined &&
            ratingsPreview.documents[0].logo !== "" ? (
              <div className="ProjectPreviewImgMobile">
                <LazyLoadImg
                  // @ts-ignore
                  imgUrl={ratingsPreview.documents[0].logo}
                />
              </div>
            ) : (
              <Avatar
                sx={{
                  bgcolor: "#F5E3FF",
                  color: "##39393c",
                  width: "30px",
                  height: "30px",
                  borderRadius: "50%",
                  fontSize: "12px",
                }}
                alt={ratingsPreview.documents[0].listingName}
              >
                {ratingsPreview.documents[0].listingName.substr(0, 2)}
              </Avatar>
            )}
            <div className="ProjectPreviewInnerMobile">
              <p className="ProjectPreviewTitleMobile">
                {ratingsPreview.documents[0].listingName}
              </p>
              <p className="ProjectPreviewTextMobile">
                <Rating
                  value={ratingsPreview.documents[0].rating}
                  readOnly
                  sx={{
                    transform: "scale(1) translateY(0px) translateX(0px)",
                    transition: "all 0.5s ease-in-out",

                    "@media (min-width: 500px)": {
                      transform:
                        "scale(0.6) translateY(-4px) translateX(-30px)",
                    },
                    "@media (min-width: 1000px)": {
                      transform:
                        "scale(0.8) translateY(-2px) translateX(-11px)",
                    },
                    "@media (min-width: 1500px)": {
                      transform: "scale(1) translateY(0px) translateX(0px)",
                    },
                  }}
                  size="small"
                />
              </p>
            </div>
          </div>
        ) : (
          <div className="ProjectPreviewOuterMobile">
            <Skeleton
              variant="circular"
              sx={{ width: "28px", height: "28px", backgroundColor: "#F5E3FF" }}
            />
            <div className="ProjectPreviewInnerMobile">
              <Skeleton variant="text" sx={{ fontSize: "8px", width: "30%" }} />
              <Skeleton
                variant="text"
                sx={{ fontSize: "7px", width: "100%" }}
              />
              <Skeleton
                variant="text"
                sx={{ fontSize: "7px", width: "100%" }}
              />
            </div>
          </div>
        )}
        {ratingsPreview.isFetched ? (
          <div className="RatingsPreviewOuterMobile">
            {ratingsPreview.documents[1].logo !== null &&
            ratingsPreview.documents[1].logo !== undefined &&
            ratingsPreview.documents[1].logo !== "" ? (
              <div className="ProjectPreviewImgMobile">
                <LazyLoadImg
                  // @ts-ignore
                  imgUrl={ratingsPreview.documents[1].logo}
                />
              </div>
            ) : (
              <Avatar
                sx={{
                  bgcolor: "#F5E3FF",
                  color: "##39393c",
                  width: "30px",
                  height: "30px",
                  borderRadius: "50%",
                  fontSize: "12px",
                }}
                alt={ratingsPreview.documents[1].listingName}
              >
                {ratingsPreview.documents[1].listingName.substr(0, 2)}
              </Avatar>
            )}
            <div className="ProjectPreviewInnerMobile">
              <p className="ProjectPreviewTitleMobile">
                {ratingsPreview.documents[1].listingName}
              </p>
              <p className="ProjectPreviewTextMobile">
                <Rating
                  value={ratingsPreview.documents[1].rating}
                  readOnly
                  sx={{
                    transform: "scale(1) translateY(0px) translateX(0px)",
                    transition: "all 0.5s ease-in-out",

                    "@media (min-width: 500px)": {
                      transform:
                        "scale(0.6) translateY(-4px) translateX(-30px)",
                    },
                    "@media (min-width: 1000px)": {
                      transform:
                        "scale(0.8) translateY(-2px) translateX(-11px)",
                    },
                    "@media (min-width: 1500px)": {
                      transform: "scale(1) translateY(0px) translateX(0px)",
                    },
                  }}
                  size="small"
                />
              </p>
            </div>
          </div>
        ) : (
          <div className="ProjectPreviewOuterMobile">
            <Skeleton
              variant="circular"
              sx={{ width: "28px", height: "28px", backgroundColor: "#F5E3FF" }}
            />
            <div className="ProjectPreviewInner">
              <Skeleton variant="text" sx={{ fontSize: "8px", width: "30%" }} />
              <Skeleton
                variant="text"
                sx={{ fontSize: "7px", width: "100%" }}
              />
              <Skeleton
                variant="text"
                sx={{ fontSize: "7px", width: "100%" }}
              />
            </div>
          </div>
        )}
        {ratingsPreview.isFetched ? (
          <div className="RatingsPreviewOuterMobile">
            {ratingsPreview.documents[2].logo !== null &&
            ratingsPreview.documents[2].logo !== undefined &&
            ratingsPreview.documents[2].logo !== "" ? (
              <div className="ProjectPreviewImgMobile">
                <LazyLoadImg
                  // @ts-ignore
                  imgUrl={ratingsPreview.documents[2].logo}
                />
              </div>
            ) : (
              <Avatar
                sx={{
                  bgcolor: "#F5E3FF",
                  color: "##39393c",
                  width: "30px",
                  height: "30px",
                  borderRadius: "50%",
                  fontSize: "12px",
                }}
                alt={ratingsPreview.documents[2].listingName}
              >
                {ratingsPreview.documents[2].listingName.substr(0, 2)}
              </Avatar>
            )}
            <div className="ProjectPreviewInnerMobile">
              <p className="ProjectPreviewTitleMobile">
                {ratingsPreview.documents[2].listingName}
              </p>
              <p className="ProjectPreviewTextMobile">
                <Rating
                  value={ratingsPreview.documents[2].rating}
                  readOnly
                  size="small"
                  sx={{
                    transform: "scale(1) translateY(0px) translateX(0px)",
                    transition: "all 0.5s ease-in-out",

                    "@media (min-width: 500px)": {
                      transform:
                        "scale(0.6) translateY(-4px) translateX(-30px)",
                    },
                    "@media (min-width: 1000px)": {
                      transform:
                        "scale(0.8) translateY(-2px) translateX(-11px)",
                    },
                    "@media (min-width: 1500px)": {
                      transform: "scale(1) translateY(0px) translateX(0px)",
                    },
                  }}
                />
              </p>
            </div>
          </div>
        ) : (
          <div className="ProjectPreviewOuterMobile">
            <Skeleton
              variant="circular"
              sx={{ width: "28px", height: "28px", backgroundColor: "#F5E3FF" }}
            />
            <div className="ProjectPreviewInnerMobile">
              <Skeleton variant="text" sx={{ fontSize: "8px", width: "30%" }} />
              <Skeleton
                variant="text"
                sx={{ fontSize: "7px", width: "100%" }}
              />
              <Skeleton
                variant="text"
                sx={{ fontSize: "7px", width: "100%" }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
