import { marked } from 'marked';

export const isValidUrl = (urlString: string) => {
  var urlPattern = new RegExp(
    "^(https?:\\/\\/)?" + // validate protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // validate fragment locator
  return !!urlPattern.test(urlString);
};

export const isValidEmail = (email: string) => {
  var emailPattern = new RegExp(
    "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$"
  );
  return !!emailPattern.test(email);
};

export const isValidXHandle = (xHandler: string) => {
  var xHandlerPattern = new RegExp("@[a-zA-Z0-9_]{0,15}");
  return !!xHandlerPattern.test(xHandler);
};

export const isValidInstagramHandle = (instagramHandle: string) => {
  var instagramHandlePattern = new RegExp(
    "^([A-Za-z0-9._](?:(?:[A-Za-z0-9._]|(?:.(?!.))){2,28}(?:[A-Za-z0-9._]))?)$"
  );
  return !!instagramHandlePattern.test(instagramHandle);
};

export const isValidTelegramHandle = (telegramHandle: string) => {
  var telegramHandlePattern = new RegExp(
    "^([A-Za-z0-9._](?:(?:[A-Za-z0-9._]|(?:.(?!.))){2,28}(?:[A-Za-z0-9._]))?)$"
  );
  return !!telegramHandlePattern.test(telegramHandle);
};

export const isValidMediumHandle = (mediumHandler: string) => {
  var mediumHandlerPattern = new RegExp("@[a-zA-Z0-9_]{0,15}");
  return !!mediumHandlerPattern.test(mediumHandler);
};

export const isValidFacebookUrl = (facebookHandler: string) => {
  var facebookHandlerPattern = new RegExp("(?:(?:http|https):\/\/)?(?:www.)?facebook.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[?\w\-]*\/)?(?:profile.php\?id=(?=\d.*))?([\w\.-]*)?");
  return !!facebookHandlerPattern.test(facebookHandler);
};

export const removeUrlPrefix = (url: string) => {
  let temp = url;

  if (temp.startsWith("http://")) {
    temp = temp.slice(7);
  } else if (temp.startsWith("https://")) {
    temp = temp.slice(8);
  }

  if (temp.startsWith("www.")) {
    temp = temp.slice(4);
  }

  if (temp.endsWith("/")) {
    temp = temp.slice(0, -1);
  }

  return temp;
};

export const removeTwitterPrefix = (twitterHandle: string) => {
  let temp = twitterHandle;

  if (temp.startsWith("@")) {
    temp = temp.slice(1);
  }
  return temp;
};

export const parseIpfsLink = (ipfsLink: string) => {
  let temp = ipfsLink;
  if (ipfsLink.startsWith("ipfs://")) {
    temp = temp.slice(7);

    return "https://ipfs.io/ipfs/" + temp;
  } else {
    return ipfsLink;
  }
};

export const testIfMarkdown = (value: string) => {
  const tokenTypes: string[] = [];
  
  // https://marked.js.org/using_pro#tokenizer
  marked(value, {
    walkTokens: (token) => {
      tokenTypes.push(token.type);
    },
  });

  const isMarkdown = [
    'space',
    'code',
    'fences',
    'heading',
    'hr',
    'link',
    'blockquote',
    'list',
    'html',
    'def',
    'table',
    'lheading',
    'escape',
    'tag',
    'reflink',
    'strong',
    'codespan',
    'url',
  ].some((tokenType) => tokenTypes.includes(tokenType));

  return isMarkdown;
};