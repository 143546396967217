import React from "react";
import styles from "../ui.module.css";

function SubstackLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 20"
      viewBox="0 0 500 499.93"
      className={styles.socialsLogoSvg}
    >
      <path
        fill="#3a3a3d"
        d="M987.67 672.65c42.7 131.27-29.09 272.29-160.36 315s-272.29-29.1-315-160.36 29.09-272.29 160.36-315a249.92 249.92 0 01315 160.36zm-237.6-150.12c-125.61.17-227.3 102.14-227.13 227.75s102.14 227.3 227.75 227.12S978 875.27 977.82 749.65a227.43 227.43 0 00-227.75-227.12z"
        transform="translate(-500 -500.03)"
      ></path>
      <g fill="#3a3a3d">
        <path
          d="M750.49 738.55h110c3.79 0 3.79 0 3.79 3.81V878c0 3.29-.14 3.36-3 1.77l-87.53-48.91c-7.26-4.06-14.54-8.09-21.77-12.21a3 3 0 00-3.37 0Q708 841.38 667.33 864.1q-14.36 8-28.74 16c-1.74 1-2 .8-2.06-1.28V743.05c0-4.49 0-4.5 4.4-4.5zM750.25 711.4h-110.2c-3.52 0-3.52 0-3.52-3.57v-24.11c0-3.34 0-3.35 3.32-3.35h220.83c3.29 0 3.3 0 3.3 3.36v24.32c0 3.34 0 3.35-3.32 3.35zM750.26 653h-110.4c-3.32 0-3.33 0-3.33-3.31 0-8.25.05-16.5 0-24.75 0-2.06.67-2.71 2.73-2.71q111 .06 222.09 0c2.06 0 2.73.65 2.71 2.71-.09 8.32 0 16.64 0 25 0 3.09 0 3.1-3.1 3.1z"
          transform="translate(-500 -500.03)"
        ></path>
      </g>
    </svg>
  );
}

export default SubstackLogo;
