import React from "react";
import styles from "../ui.module.css";

function TelegramLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 20"
      viewBox="0 0 500 499.93"
      className={styles.socialsLogoSvg}
    >
      <g>
        <path
          fill="#3a3a3d"
          d="M987.67 672.65c42.7 131.27-29.09 272.29-160.36 315s-272.29-29.1-315-160.36 29.09-272.29 160.36-315a249.92 249.92 0 01315 160.36zm-237.6-150.12c-125.61.17-227.3 102.14-227.13 227.75s102.14 227.3 227.75 227.12S978 875.27 977.82 749.65a227.43 227.43 0 00-227.75-227.12z"
          transform="translate(-500 -500.03)"
        ></path>
        <path
          fill="#3a3a3d"
          d="M871.8 656.5c-.38 6.65-1.34 13.25-2.3 19.84q-4.62 31.56-9.43 63.11c-3.44 22.35-7 44.68-10.53 67-1.54 9.91-2.39 19.91-4.17 29.78-1.15 6.4-2.65 12.69-6.06 18.35-4.62 7.67-11.84 10.8-20.64 8.89-7.68-1.68-14.44-5.41-20.73-9.9-20.15-14.41-41.05-27.71-61.62-41.5-4-2.68-8.07-5.32-12-8.14a15.47 15.47 0 01-6.31-9.59c-1.12-5.42 1.15-9.78 4.43-13.8 3.54-4.33 8-7.72 12-11.54 3.55-3.35 7.27-6.53 10.83-9.88 9.62-9 19.17-18.16 28.78-27.22 7.92-7.46 16-14.75 23.59-22.56 2-2 3.77-4.17 5.57-6.32a4.66 4.66 0 00.66-5.61 4.72 4.72 0 00-5.63-1.4c-3.31 1.22-6 3.47-9 5.35-19 12.21-37.18 25.57-56 38.08-10.49 7-20.67 14.41-31.06 21.53-7.92 5.43-16 10.68-25.31 13.49a31.22 31.22 0 01-18.26.39c-10.14-3-20.45-5.39-30.7-8a149.12 149.12 0 01-19.56-6.21 16.76 16.76 0 01-6.12-4 6.82 6.82 0 01-1.21-8.28c1.69-3.46 4.56-5.7 7.87-7.42 6.63-3.43 13.55-6.23 20.39-9.21q30.26-13.21 60.57-26.31c25.19-10.9 50.33-21.93 75.76-32.3 17.38-7.08 34.68-14.38 52-21.5 8.15-3.34 16.37-6.49 24.57-9.71a41.09 41.09 0 0113-3.08c8.9-.44 15 3.71 16.12 13.25a23.08 23.08 0 01.5 4.42z"
          transform="translate(-500 -500.03)"
        ></path>
      </g>
    </svg>
  );
}

export default TelegramLogo;
