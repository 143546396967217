import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { materialFormsStylesNew } from "../../../components/theme/materialGlobals";
import { isValidEmail } from "../../../components/utils/parsers";
import { httpsCallable } from "firebase/functions";
import { firebaseFunctions } from "../../../components/firebase/firebase";
import HandshakeLogo from "../../../components/ui/HandshakeLogo";
import Socials from "../../../components/ui/Socials";

import "./Footer.css";

export default function Footer() {
  const [userEmail, setUserEmail] = useState("");
  const [userEmailSub, setUserEmailSub] = useState("");
  const [userEmailVerified, setUserEmailVerified] = useState(false);

  const [isSending, setIsSending] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState(
    "Thank you for subscribing!"
  );

  const updateUserEmail = (e: any) => {
    setUserEmail(e.target.value);
    if (e.target.value === "") {
      setUserEmailSub("");
      setUserEmailVerified(false);
    } else if (isValidEmail(e.target.value) === false) {
      setUserEmailSub("Not a valid email address.");
      setUserEmailVerified(false);
    } else {
      setUserEmailSub("");
      setUserEmailVerified(true);
    }
  };

  const onSend = async () => {
    if (userEmailVerified === false) {
      return;
    }
    if (isSending === true) {
      return;
    }
    setErrorMessage("");
    setIsSending(true);
    const data = {
      email: userEmail,
    };

    try {
      const subscribe = httpsCallable(
        firebaseFunctions,
        "newsletter-subscribe"
      );
      const res: any = await subscribe(data);
      setErrorMessage(res.data.message);
      setIsSent(true);
      setIsSending(false);
    } catch (err: any) {
      console.error(err);
      setErrorMessage("Error: " + err.message);
      setIsSent(true);
      setIsSending(false);
    }
  };

  return (
    <div className="FooterMobile">
      <div className="FooterHeaderMobile">
        <HandshakeLogo />
      </div>
      <div className="FooterInnerMobile">
        <div className="FooterInnerInfoMobile">
          <p
            className="StartInnerTitleMobile"
            style={{ marginTop: "0px", marginBottom: "0px" }}
          >
            Contribute and gather points, and be part of building a brighter
            Web3.
          </p>
          <div className="FooterSocialsMobile">
            <Socials />
          </div>
        </div>
        <div className="FooterInnerButtonOuterMobile">
          {isSent ? (
          <div className="FooterInnerButtonMobile">
            <p className="FooterInnerButtonTitleMobile" style={{textAlign: 'center'}}>{errorMessage}</p>
            <Button
              variant="outlined"
              sx={{
                backgroundColor: "#ffd379",
                width: "100%",
                borderColor: "#39393c",
                borderWidth: "1px",
                borderRadius: "10px",
                height: "30px",
                color: "#39393c",
                boxShadow: "0px 1.5px 1px #39393c",
                "&:hover": {
                  backgroundColor: "#ffd379",
                  opacity: 0.9,
                },
              }}
              color="inherit"
              size="small"
              onClick={() => {
                setIsSent(false);
                setUserEmail("");
                setUserEmailSub("");
                setUserEmailVerified(false);
              }}
            >
              OK
            </Button>
          </div>
        ) : (
          <div className="FooterInnerButtonMobile">
            <p className="FooterInnerButtonTitleMobile">
              Subscribe To Our Newsletter
            </p>
            <div className={"FormOuter"}>
              <TextField
                label="Email address"
                variant="outlined"
                fullWidth
                size="small"
                sx={materialFormsStylesNew}
                value={userEmail}
                onChange={updateUserEmail}
                disabled={isSending}
              />
              <p className="FormDescriptionMobile">{userEmailSub}</p>
            </div>
            <Button
              variant="outlined"
              sx={{
                backgroundColor: "#ffd379",
                width: "100%",
                borderColor: "#39393c",
                borderWidth: "1px",
                borderRadius: "10px",
                height: "30px",
                color: "#39393c",
                boxShadow: "0px 1.5px 1px #39393c",
                "&:hover": {
                  backgroundColor: "#ffd379",
                  opacity: 0.9,
                },
              }}
              color="inherit"
              size="small"
              onClick={onSend}
            >
              {isSending ? (
                <CircularProgress size={15} color="inherit" />
              ) : (
                "Submit"
              )}
            </Button>
          </div>
        )}
        <div className="FooterLinksMobile">
          <a href="/terms" className="FooterLinkMobile">
            Terms and Conditions
          </a>
          <a href="/cookies" className="FooterLinkMobile">
            Cookies Statement
          </a>
          <a href="mailto: info@gath3r.co" className="FooterLinkMobile">
            Contact Us
          </a>
        </div>
        </div>
        
      </div>
      <p className="ProjectPreviewTitleMobile" style={{marginTop: '20px'}}>© 2024 GATHER BEYOND PTE LTD.</p>
    </div>
  );
}
