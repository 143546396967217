import Button from "@mui/material/Button";
import ListingTable from "./components/ListingTable";

import "./Listings.css";

export default function Listings() {
  return (
    <div className="ListingsMobile">
      <div className="ListingsInnerMobile">
        <div className="StartInnerInfoMobile" style={{width: '90%', marginLeft: '0%'}}>
          <p className="StartInnerTitleMobile">
            Search for anything Web3 related. Coins. NFTs, protocols, wallets,
            etc.
          </p>
          <div className="StartInnerInfoDividerMobile"></div>
          <p className="StartInnerTextMobile">
            GATH3R aims to be the the largest collection of Web3 resources, with
            user reviews, ratings, and listings. If it’s on-chain or supports
            it, you can find it here.
          </p>
          <div className="StartInnerInfoButtonsMobile" style={{marginTop: '20px'}}>
              <Button
                variant="outlined"
                sx={{
                  backgroundColor: "#FAEC72",
                  width: "140px",
                  maxWidth: "40%",
                  borderColor: "#39393C",
                  borderWidth: "1px",
                  borderRadius: "7px",
                  color: "#39393c",
                  boxShadow: "0px 1.5px 1px #39393C",
                  fontSize: "8px",
                  fontFamily: "Poppins",
                  padding: "4px",
                  textTransform: "none",
                  lineHeight: "1",
                  height:'30px',
                  "&:hover": {
                    backgroundColor: "#ffd379",
                    opacity: 0.9,
                  },
                  "@media (min-width: 600px)": {
                    fontSize: "9px",
                  },
                  "@media (min-width: 900px)": {
                    fontSize: "10px",
                  },
                  "@media (min-width: 1200px)": {
                    fontSize: "11px",
                  },
                }}
                color="inherit"
                size="small"
                onClick={() => {
                  window.open(
                    "https://app.gath3r.co/explore",
                    "_blank noreferrer"
                  );
                }}
              >
                Search Now
              </Button>
              <Button
                variant="outlined"
                sx={{
                  backgroundColor: "#A6E7CF",
                  width: "140px",
                  maxWidth: "40%",
                  borderColor: "#39393C",
                  borderWidth: "1px",
                  borderRadius: "7px",
                  color: "#39393c",
                  boxShadow: "0px 1.5px 1px #39393C",
                  fontSize: "8px",
                  fontFamily: "Poppins",
                  padding: "4px",
                  textTransform: "none",
                  lineHeight: "1",
                  height:'30px',
                  "&:hover": {
                    backgroundColor: "#ffd379",
                    opacity: 0.9,
                  },
                  "@media (min-width: 600px)": {
                    fontSize: "9px",
                  },
                  "@media (min-width: 900px)": {
                    fontSize: "10px",
                  },
                  "@media (min-width: 1200px)": {
                    fontSize: "11px",
                  },
                }}
                color="inherit"
                size="small"
                onClick={() => {
                  window.open(
                    "https://app.gath3r.co",
                    "_blank noreferrer"
                  );
                }}
              >
                List a Project
              </Button>
            </div>
        </div>
        <div className="ListingsInnerTableMobile">
            <ListingTable />
        </div>
      </div>
    </div>
  );
}
