import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import moment from "moment";

import DOMPurify from "dompurify";
import { useListingDialogState } from "../../components/utils/globalStates";
import { newsEnums } from "../../components/utils/enums";
import {
  collection,
  query,
  where,
  getDocs,
  getFirestore,
  orderBy,
  limit,
  doc,
} from "firebase/firestore";
import {
  firestoreDb,
  firebaseAnalytics,
} from "../../components/firebase/firebase";

import "./Info.css";
import { Skeleton } from "@mui/material";

moment().format();

export default function Info() {
  const [news, setNews] = useState([]);
  const listtingDialogState = useListingDialogState();

  useEffect(() => {
    fetchNews();
  }, []);

  const fetchNews = async () => {
    let tempNews: any = [];
    for (let i = 0; i < newsEnums.length; i++) {
      try {
        let q = query(
          collection(firestoreDb, `listings/${newsEnums[i].value}/rss`),
          orderBy("date", "desc"),
          limit(1)
        );

        const querySnapshot = await getDocs(q);

        querySnapshot.forEach((doc) => {
          tempNews.push({
            ...doc.data(),
            value: newsEnums[i].value,
            name: newsEnums[i].name,
          });
        });
      } catch (error) {
        console.log("Error getting documents: ", error);
      }
    }
    setNews(tempNews);
  };
  return (
    <div className="Info">
      <div className="InfoInner">
        <p className="StartInnerTitle">
          News and reviews on everything Web3 and Blockchain, all in one easy to
          navigate place.
        </p>
        <div className="StartInnerInfoDivider"></div>
        <p className="StartInnerText">
          Find news and user reviews and ratings on thousands of projects. News
          is gathered from multiple sources and conveniently displayed on each
          projects page, do simplify your journey and cut down the time it takes
          you to DYOR.
        </p>
        <div className="InfoRow">
          {news.length > 0
            ? news.map((item: any, index) => {
                return (
                  <div className="InfoRowObject" key={index}>
                    <Button
                      variant="outlined"
                      sx={{
                        backgroundColor: "#ffd379",
                        width: "100%",
                        borderColor: "#39393C",
                        borderWidth: "1px",
                        borderRadius: "7px",
                        color: "#39393c",
                        boxShadow: "0px 1.5px 1px #39393C",
                        fontSize: "8px",
                        fontFamily: "Poppins",
                        padding: "6px",
                        textTransform: "none",
                        lineHeight: "1",
                        "&:hover": {
                          backgroundColor: "#FAEC72",
                          opacity: 0.9,
                        },
                        "@media (min-width: 600px)": {
                          fontSize: "9px",
                        },
                        "@media (min-width: 900px)": {
                          fontSize: "10px",
                        },
                        "@media (min-width: 1200px)": {
                          fontSize: "11px",
                        },
                      }}
                      color="inherit"
                      size="small"
                      onClick={() => {
                        listtingDialogState.setListingId(item.value);
                      }}
                    >
                      {item.name}
                    </Button>
                    <div
                      className="InfoRowObjectInner"
                      onClick={() => {
                        window.open(item.link, "_blank noreferrer");
                      }}
                    >
                      <p className="InfoRowObjectInnerTitle">{item.title}</p>
                      <div
                        className="markdownOuterPreview"
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(item.snippet, {
                            FORBID_ATTR: ["style"],
                            FORBID_TAGS: ["style", "a", "strong"],
                          }),
                        }}
                      ></div>
                      <div className="InfoRowObjectInnerFooter">
                        <p className="InfoRowObjectInnerAuthor">{`${
                          item.author
                        } @ ${item.feed} · ${moment(item.date).fromNow()}`}</p>
                      </div>
                    </div>
                  </div>
                );
              })
            : newsEnums.map((item, i) => (
                <div className="InfoRowObject" key={i}>
                  <Button
                    variant="outlined"
                    sx={{
                      backgroundColor: "#ffd379",
                      width: "100%",
                      borderColor: "#39393C",
                      borderWidth: "1px",
                      borderRadius: "7px",
                      color: "#39393c",
                      boxShadow: "0px 1.5px 1px #39393C",
                      fontSize: "8px",
                      fontFamily: "Poppins",
                      padding: "6px",
                      textTransform: "none",
                      lineHeight: "1",
                      "&:hover": {
                        backgroundColor: "#FAEC72",
                        opacity: 0.9,
                      },
                      "@media (min-width: 600px)": {
                        fontSize: "9px",
                      },
                      "@media (min-width: 900px)": {
                        fontSize: "10px",
                      },
                      "@media (min-width: 1200px)": {
                        fontSize: "11px",
                      },
                    }}
                    color="inherit"
                    size="small"
                    onClick={() => {
                      listtingDialogState.setListingId(item.value);
                    }}
                  >
                    {item.name}
                  </Button>
                  <Skeleton
                    variant="rectangular"
                    sx={{
                      width: "100%",
                      height: "220px",
                      marginTop: "20px",
                      borderRadius: "6px",
                    }}
                  />
                </div>
              ))}
        </div>
        {/* <div className="InfoRow">
          <div className="InfoRowObject">
            <Button
              variant="outlined"
              sx={{
                backgroundColor: "#FFD379",
                width: "100%",
                borderColor: "#39393C",
                borderWidth: "1px",
                borderRadius: "7px",
                color: "#39393c",
                boxShadow: "0px 1.5px 1px #39393C",
                fontSize: "8px",
                fontFamily: "Poppins",
                padding: "6px",
                textTransform: "none",
                lineHeight: "1",
                "&:hover": {
                  backgroundColor: "#ffd379",
                  opacity: 0.9,
                },
                "@media (min-width: 600px)": {
                  fontSize: "9px",
                },
                "@media (min-width: 900px)": {
                  fontSize: "10px",
                },
                "@media (min-width: 1200px)": {
                  fontSize: "11px",
                },
              }}
              color="inherit"
              size="small"
              onClick={() => {
                window.open(
                  "https://app.gath3r.co/auth/register",
                  "_blank noreferrer"
                );
              }}
            >
              Sign Up
            </Button>
            <div className="InfoRowObjectInner"></div>
          </div>
        </div>*/}
      </div>
    </div>
  );
}
