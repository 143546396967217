import Desktop from "./Desktop";
import Mobile from "./Mobile";
import { useDeviceState } from "../components/utils/globalStates";

export default function StartView() {

  const deviceState = useDeviceState((state) => state)

  return deviceState.isMobile ? <Mobile /> : <Desktop />; 
}
