import Button from "@mui/material/Button";
import DashboardPreview from "./DashoardPreview";

import "./Dashboard.css";

export default function Dashboard() {
  return (
    <div className="DashboardMobile">
      <div className="DashboardInnerMobile">
        <div className="DashboardInnerInfoMobile">
          <p className="StartInnerTitleMobile">
            Personalize your dashboard, list, find, follow and rate Web3
            projects.
          </p>
          <Button
            variant="outlined"
            sx={{
              backgroundColor: "#FAEC72",
              width: "100%",
              borderColor: "#39393C",
              borderWidth: "1px",
              borderRadius: "7px",
              color: "#39393c",
              boxShadow: "0px 1.5px 1px #39393C",
              fontSize: "8px",
              fontFamily: "Poppins",
              padding: "4px",
              textTransform: "none",
              lineHeight: "1",
              marginTop: "20px",
              height: "30px",
              "&:hover": {
                backgroundColor: "#ffd379",
                opacity: 0.9,
              },
              "@media (min-width: 600px)": {
                fontSize: "9px",
              },
              "@media (min-width: 900px)": {
                fontSize: "10px",
              },
              "@media (min-width: 1200px)": {
                fontSize: "11px",
              },
            }}
            color="inherit"
            size="small"
            onClick={() => {
              window.open(
                "https://app.gath3r.co",
                "_blank noreferrer"
              );
            }}
          >
            Sign Up and customize your dashboard
          </Button>
          <div className="StartInnerInfoDividerMobile"></div>
          <p className="StartInnerTextMobile">
            Organise your Web3 database the way you like it. See everything
            that’s important to you at a glance, and keep tabs on trending
            projects.
          </p>
        </div>
        <div className="DashboardInnerObjectsOuterMobile">
            <DashboardPreview />
            </div>
      </div>
    </div>
  );
}
