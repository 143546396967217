import React, { useState } from "react";
import { AsyncImage } from "loadable-image";
import { CircularProgress } from "@mui/material";
import InsertPhotoOutlinedIcon from "@mui/icons-material/InsertPhotoOutlined";
import { Fade } from "transitions-kit";
import "./ui.css";

export const LazyLoadImg = ({ imgUrl, radiusStyle }: any) => {
  return (
    <AsyncImage
      src={imgUrl}
      style={{ width: "100%", aspectRatio: "1/1", borderRadius: radiusStyle}}
      Transition={Fade}
      loader={
        <div className="LazyLoadImgLoading">
          <CircularProgress
          size={'20%'}
            sx={{
              color: '#39393c',
            }}
          />
        </div>
      }
      error={
        <div className="LazyLoadImgLoading">
          <InsertPhotoOutlinedIcon
            sx={{
              color: '#39393c',
              width: "30%",
              height: "30%",
              opacity: 0.5,
            }}
          />
        </div>
      }
    />
  );
};
