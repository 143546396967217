export const categoryEnums = [
  {
    displayname: "Artificial Intelligence (AI)",
    value: "ai",
  },
  {
    displayname: "Blockchain",
    value: "blockchain",
  },
  {
    displayname: "Bridges",
    value: "bridges",
  },
  {
    displayname: "Centralized Exchanges (CEX)",
    value: "cex",
  },
  {
    displayname: "Community",
    value: "community",
  },
  {
    displayname: "DAO",
    value: "dao",
  },
  {
    displayname: "Decentralized Exchanges (DEX)",
    value: "dex",
  },
  {
    displayname: "Defi",
    value: "defi",
  },
  {
    displayname: "Derivatives",
    value: "derivatives",
  },
  {
    displayname: "Ecosystem",
    value: "ecosystem",
  },
  {
    displayname: "Exchange",
    value: "exchange",
  },
  {
    displayname: "Game",
    value: "game",
  },
  {
    displayname: "Internet of Things (IoT)",
    value: "iot",
  },
  {
    displayname: "Launchpads",
    value: "launchpads",
  },
  {
    displayname: "Lending/Borrowing ",
    value: "lending-borrowing",
  },
  {
    displayname: "Market Maker",
    value: "market-maker",
  },
  {
    displayname: "Media",
    value: "media",
  },
  {
    displayname: "Meme",
    value: "meme",
  },
  {
    displayname: "Metaverse",
    value: "metaverse",
  },
  {
    displayname: "Move to Earn",
    value: "move-to-earn",
  },
  {
    displayname: "NFT",
    value: "nft",
  },
  {
    displayname: "NFT Marketplaces",
    value: "nft-marketplaces",
  },
  {
    displayname: "Node",
    value: "node",
  },
  {
    displayname: "Oracle",
    value: "oracle",
  },
  {
    displayname: "Protocol",
    value: "protocol",
  },
  {
    displayname: "Real World Assets (RWA)",
    value: "rwa",
  },
  {
    displayname: "ReFi",
    value: "refi",
  },
  {
    displayname: "SocialFi",
    value: "socialfi",
  },
  {
    displayname: "Stable Coins",
    value: "stable-coins",
  },
  {
    displayname: "Storage",
    value: "storage",
  },
  {
    displayname: "Tools",
    value: "tools",
  },
  {
    displayname: "VC",
    value: "vc",
  },
  {
    displayname: "Wallet",
    value: "wallet",
  },
];

export const pfpEnums = {
  background: {
    low: 1,
    high: 12,
  },
  bodyBoy: {
    low: 1,
    high: 14,
  },
  bodyGirl: {
    low: 11,
    high: 20,
  },
  faceBoy: {
    low: 11,
    high: 24,
  },
  faceGirl: {
    low: 11,
    high: 20,
  },
  headBoy: {
    low: 1,
    high: 19,
  },
  headGirl: {
    low: 1,
    high: 21,
  },
};

export const newsEnums = [
  {
    name: "Ethereum",
    value: "ethereum",
  },
  {
    name: "Pepe",
    value: "pepe",
  },
  {
    name: "Solana",
    value: "solana",
  },
  {
    name: "Helium",
    value: "helium",
  },
  {
    name: "Metamask",
    value: "metamask",
  },
  {
    name: "Raydium",
    value: "raydium",
  },
];
