import React, { useState, useEffect } from "react";
import { AsyncImage } from "loadable-image";
import { CircularProgress } from "@mui/material";

import { pfpEnums } from "../utils/enums";
import "./ui.css";
import { LazyLoadImg } from "./LazyLoadImg";

export const PfpViewer = ({
  pfpString,
  randomize,
}: {
  pfpString: string;
  randomize: boolean;
}) => {
  const [loading, setLoading] = useState(true);
  const [layersArray, setLayersArray] = useState<string[]>([]);

  // Randomize number between high and low properties
  const randomizeNumber = (low: number, high: number) => {
    return Math.floor(Math.random() * (high - low + 1) + low);
  };

  // Listen for pfp string to update layers array
  useEffect(() => {
    if (randomize) {
      setLoading(true);
      const tempArray: string[] = [];
      tempArray.push(
        `https://firebasestorage.googleapis.com/v0/b/gath3r-co.appspot.com/o/public%2Fpfp%2Fbackground%2FBackground${randomizeNumber(
          pfpEnums.background.low,
          pfpEnums.background.high
        )}.png?alt=media&token=3cf8c79e-8712-44dd-942a-702559718e14`
      );
      tempArray.push(
        `https://firebasestorage.googleapis.com/v0/b/gath3r-co.appspot.com/o/public%2Fpfp%2Fbody%2FBoy-Body-${randomizeNumber(
          pfpEnums.bodyBoy.low,
          pfpEnums.bodyBoy.high
        )}.png?alt=media&token=e75ed607-cb93-4ece-a455-700d12628454`
      );
      tempArray.push(
        `https://firebasestorage.googleapis.com/v0/b/gath3r-co.appspot.com/o/public%2Fpfp%2Fface%2FBoy_Face_${randomizeNumber(
          pfpEnums.faceBoy.low,
          pfpEnums.faceBoy.high
        )}.png?alt=media&token=cf8059fb-2f7c-4e14-bbf2-23285addfe39`
      );
      tempArray.push(
        `https://firebasestorage.googleapis.com/v0/b/gath3r-co.appspot.com/o/public%2Fpfp%2Fhead%2FBoy_Head_1${randomizeNumber(
          pfpEnums.headBoy.low,
          pfpEnums.headBoy.high
        )}.png?alt=media&token=81dfc699-ae64-4066-93bf-1daf7a2bffe0`
      );
      setLayersArray(tempArray);
      setLoading(false);
    } else {
      if (pfpString === undefined) return;
      if (pfpString === "") return;
      setLoading(true);
      const tempArray: string[] = [];
      const pfpLayers = pfpString.split(";");
      if (pfpLayers[0] === "girl") {
        tempArray.push(
          `https://firebasestorage.googleapis.com/v0/b/gath3r-co.appspot.com/o/public%2Fpfp%2Fbackground%2FBackground${pfpLayers[1]}.png?alt=media&token=3cf8c79e-8712-44dd-942a-702559718e14`
        );
        tempArray.push(
          `https://firebasestorage.googleapis.com/v0/b/gath3r-co.appspot.com/o/public%2Fpfp%2Fbody%2FGirl_Body_${pfpLayers[2]}.png?alt=media&token=3043cfec-3d15-4680-b425-83334dc07cac`
        );
        tempArray.push(
          `https://firebasestorage.googleapis.com/v0/b/gath3r-co.appspot.com/o/public%2Fpfp%2Fface%2FGirl_Face_${pfpLayers[3]}.png?alt=media&token=cf8059fb-2f7c-4e14-bbf2-23285addfe39`
        );
        tempArray.push(
          `https://firebasestorage.googleapis.com/v0/b/gath3r-co.appspot.com/o/public%2Fpfp%2Fhead%2FGirl_Head_1${pfpLayers[4]}.png?alt=media&token=81dfc699-ae64-4066-93bf-1daf7a2bffe0`
        );
        setLayersArray(tempArray);
        setLoading(false);
      } else {
        tempArray.push(
          `https://firebasestorage.googleapis.com/v0/b/gath3r-co.appspot.com/o/public%2Fpfp%2Fbackground%2FBackground${pfpLayers[1]}.png?alt=media&token=3cf8c79e-8712-44dd-942a-702559718e14`
        );
        tempArray.push(
          `https://firebasestorage.googleapis.com/v0/b/gath3r-co.appspot.com/o/public%2Fpfp%2Fbody%2FBoy-Body-${pfpLayers[2]}.png?alt=media&token=e75ed607-cb93-4ece-a455-700d12628454`
        );
        tempArray.push(
          `https://firebasestorage.googleapis.com/v0/b/gath3r-co.appspot.com/o/public%2Fpfp%2Fface%2FBoy_Face_${pfpLayers[3]}.png?alt=media&token=cf8059fb-2f7c-4e14-bbf2-23285addfe39`
        );
        tempArray.push(
          `https://firebasestorage.googleapis.com/v0/b/gath3r-co.appspot.com/o/public%2Fpfp%2Fhead%2FBoy_Head_1${pfpLayers[4]}.png?alt=media&token=81dfc699-ae64-4066-93bf-1daf7a2bffe0`
        );
        setLayersArray(tempArray);
        setLoading(false);
      }
    }
  }, [pfpString, randomize]);

  return (
    <div
      className="PfpViewer"
    >
      {loading ? (
        <CircularProgress
          style={{ color: '#FFD379' }}
        />
      ) : (
        layersArray.map((layer, index) => {
          return (
            <div key={index} className="PfpViewerImg">
              <LazyLoadImg imgUrl={layer} />
            </div>
          );
        })
      )}
    </div>
  );
};
